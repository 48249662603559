import { ReactChild, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useToggle, useClickAway } from 'react-use'
import classNames from 'classnames'
import { List, Share, Link } from '@vhl/icons/post'
import { FacebookOutline, ZaloOutline } from '@vhl/icons/social'
import { TableOfContents } from '../../types'
import TableOfContent from '../TableOfContent'
import { copyCurrentWindowLocation } from '@/utils'
import { share } from '@/utils/social'
import LikeButton from './LikeButton'
import { useBreakpoints } from '@/hooks/useBreakpoints'

function PopoverWrapper({
  isOpen,
  toggle,
  children,
  className,
  iconId,
}: {
  isOpen: boolean
  toggle: (nextValue?: boolean) => void
  className?: string
  children?: ReactChild
  iconId: string
}) {
  const ref = useRef<HTMLDivElement>(null)
  const popoverClass = classNames(
    { [className || '']: true },
    {
      'border-[1px] border-blue-500 transition transform duration-300 absolute bottom-0': true,
    },
    { 'opacity-0': !isOpen },
    { 'opacity-1': isOpen }
  )
  useEffect(() => {
    // if not open, move ToC to very far from the right, because
    // this will make it cannot be interactive
    // (opacity = 0 can make it invisible, but it will still be interactive)
    if (isOpen) {
      if (ref.current) {
        ref.current.style.right = '0'
      }
    } else {
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.right = '1000px'
        }
      }, 150)
    }
  }, [isOpen])
  useClickAway(ref, e => {
    const target = e.target as HTMLElement
    // if user click on list icon, do nothing
    if (target.id === iconId) return
    toggle(false)
  })
  return (
    <div className={popoverClass} ref={ref}>
      {children}
    </div>
  )
}

interface NavbarInteractiveProps {
  postId: number
  tableOfContents: TableOfContents[]
}

const NavbarInteractive = ({
  postId,
  tableOfContents,
}: NavbarInteractiveProps) => {
  const [isTableOfContentsOpen, toggleTableOfContents] = useToggle(false)
  const [isShareListOpen, toggleIsShareList] = useToggle(false)
  const { isDesktop } = useBreakpoints()
  function ShareListItem({
    onClick,
    icon,
    text,
    clickedText,
  }: {
    children?: ReactChild
    icon?: ReactChild
    text?: string
    clickedText?: string
    onClick?: () => void
  }) {
    if (!clickedText) clickedText = text

    const [isItemClicked, toggleItemClicked] = useToggle(false)
    const className = classNames(
      'flex items-center px-6 py-5.25 space-x-2 transition transform duration-300',
      {
        'bg-blue-500 text-white stroke-white': isItemClicked,
      },
      { 'stroke-blue-500': !isItemClicked }
    )
    function onItemClicked() {
      toggleItemClicked(true)
      if (onClick) onClick()
      setTimeout(() => {
        toggleItemClicked(false)
      }, 1500)
    }

    return (
      <div className={className} onClick={onItemClicked}>
        {icon}
        <p>{!isItemClicked ? text : clickedText}</p>
      </div>
    )
  }

  // Create a portal in document.body so we need to check window exists (current env is browser)
  if (typeof window === 'undefined') return null
  // Disable on desktop
  if (isDesktop) return null

  return createPortal(
    <div className="sticky bottom-0 flex flex-col border-t-[1px] border-gray-300">
      <div className="relative">
        <PopoverWrapper
          className="flex flex-col w-full px-4 py-2 space-y-3 bg-white"
          iconId="nav-list"
          isOpen={isTableOfContentsOpen}
          toggle={toggleTableOfContents}
        >
          <TableOfContent>
            <>
              <TableOfContent.Heading className="text-blue-500 heading-4" />
              <div className="max-h-[100px] overflow-y-scroll">
                <TableOfContent.Contents
                  tableOfContents={tableOfContents}
                  contentBoxClass="flex flex-col space-y-4"
                  contentTextDefault="text-gray-800"
                />
              </div>
            </>
          </TableOfContent>
        </PopoverWrapper>
        <PopoverWrapper
          isOpen={isShareListOpen}
          toggle={toggleIsShareList}
          iconId="nav-share"
          className="flex flex-col text-blue-500 bg-white caption"
        >
          <>
            <ShareListItem
              onClick={() => copyCurrentWindowLocation()}
              icon={<Link />}
              text="Copy link"
              clickedText="Copied!"
            />
            <ShareListItem
              onClick={() =>
                share('facebook', document.title, window.location.href)
              }
              icon={<FacebookOutline />}
              text="Facebook"
            />
            <ShareListItem
              onClick={() =>
                share('facebook', document.title, window.location.href)
              }
              icon={<ZaloOutline />}
              text="Zalo"
            />
          </>
        </PopoverWrapper>
      </div>
      <div className="flex justify-between w-full py-4 bg-white tablet:justify-center tablet:space-x-40 px-14 stroke-gray-400">
        <LikeButton
          postId={postId}
          iconColor="stroke-gray-400"
          iconLikedColor="fill-blue-500 stroke-white"
          textColor="text-gray-400"
          textLikedColor="text-blue-500"
        />
        <List
          id="nav-list"
          width={32}
          height={32}
          onClick={toggleTableOfContents}
        />
        <Share
          id="nav-share"
          width={32}
          height={32}
          onClick={() => {
            if (navigator.share) {
              share('facebook', document.title, window.location.href)
            } else {
              toggleIsShareList()
            }
          }}
        />
      </div>
    </div>,
    document.body
  )
}

export default NavbarInteractive
